<template>
  <transition name="fade" mode="out-in" :appear="appear">
    <slot />
  </transition>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script>
export default {
  props: {
    appear: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
